@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,900&family=Outfit:wght@400;500;600;700;800&family=Raleway:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  margin: 0;
  padding: 0 !important;
  background-color: #fcfcfc;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-Roboto {
  font-family: "Roboto", serif;
}

.font-Raleway {
  font-family: "Raleway", sans-serif;
}

.font-Nunito {
  font-family: "Nunito",serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;